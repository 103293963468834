<template>
  <SlModal
    :id="id"
    :width="720"
    :title="$t('Web.Dashboard.Widget.ModalTitle')"
    :loading="isLoading"
    :updating="isUpdating"
    persistent
    @on-enter="handleSubmit"
    @created="onCreated"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <ValidationObserver
      v-if="config"
      ref="observer"
      class="modal-content create-widget"
    >
      <div class="create-widget__block">
        <SlValidate
          v-slot="{ invalid }"
          rules="required"
          vid="widgetName"
        >
          <SlInput
            v-model="widgetName"
            :label="$t('Web.Dashboard.Widget.Name')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
        <SlSelect
          v-if="config.widgetType.enabled"
          v-model="config.widgetType.val"
          :options="config.widgetType.selection"
          :select-label="config.widgetType.name"
          @input="(value) => updateWidget({
            block: blockTypes.WIDGET_TYPE,
            value
          })"
        />
      </div>
      <template v-if="config.reportConfig.length">
        <div class="create-widget__header">
          {{ $t('Web.Dashboard.Widget.ReportConfigurations') }}
        </div>
        <div
          v-for="(reportConfig, configIndex) in config.reportConfig"
          :key="configIndex"
          class="create-widget__block create-widget__block--row"
          :class="{
            'create-widget__block--compared': isComparedBlock(configIndex)
          }"
        >
          <div
            v-for="item in reportConfig"
            :key="item.requestKey"
            :class="['create-widget__control', {
              'w-50': isSplitControls(item)
            }]"
          >
            <SlSelect
              v-if="item.type === controlType.SELECT"
              v-model="item.val"
              :options="item.selection"
              :select-label="item.name"
              @input="(value) => updateWidget({
                block: blockTypes.REPORT_CONFIG,
                index: configIndex,
                requestKey: item.requestKey,
                value
              })"
            />
            <SlCheckbox
              v-else-if="item.type === controlType.CHECKBOX"
              v-model="item.val"
              :label="item.name"
              :disabled="!item.enabled"
              @change="(value) => updateWidget({
                block: blockTypes.REPORT_CONFIG,
                index: configIndex,
                requestKey: item.requestKey,
                value
              })"
            />
            <SlValidate
              v-else-if="item.type === controlType.NUMBER_INPUT"
              v-slot="{ invalid, validate }"
              :rules="`required|between:${item.min},${item.max}`"
              :vid="`${item.requestKey}-${configIndex}`"
            >
              <SlControlInput
                v-model="item.val"
                :label="item.name"
                :min="item.min"
                :max="item.max"
                :disabled="!item.enabled"
                :is-invalid="invalid"
                @input="(value) => updateHistoricalPeriod({
                  block: blockTypes.REPORT_CONFIG,
                  index: configIndex,
                  requestKey: item.requestKey,
                  value,
                  validate
                })"
              />
            </SlValidate>
            <SlControl
              v-else-if="item.type === controlType.CONTROL"
              v-model="item.val"
              :label="item.name"
              :options="getChartControlOptions(item.selection)"
              options-icon-style-class="fill-off size-20 color-grey-60"
              @input="(value) => updateWidget({
                block: blockTypes.REPORT_CONFIG,
                index: configIndex,
                requestKey: item.requestKey,
                value
              })"
            />
          </div>
          <div class="create-widget__block-divider">
            {{ $t('Web.Dashboard.Widget.Compare') }}
          </div>
        </div>
      </template>
      <template v-if="config.aggregation">
        <div class="create-widget__header">
          {{ $t('Web.Dashboard.Widget.Aggregation') }}
        </div>
        <div class="create-widget__block">
          <!--    todo add Aggregate periods by select    -->
          <div class="create-widget__control">
            <SlSelect
              v-if="config.aggregation.type === controlType.COMBOBOX"
              :value="config.aggregation.val"
              :options="getAggregationOptions(config.aggregation.selection)"
              :select-label="config.aggregation.name"
              :close-on-select="false"
              class="sl-aggregation-select"
              allow-empty
              @input="updateAggregation"
            >
              <template #selection>
                <span
                  :class="['sl-aggregation-select__value', {
                    'special': isLabelSpecial(config.aggregation)
                  }]"
                >
                  {{ getAggregationLabel(config.aggregation) }}
                </span>
              </template>
              <template #selectOption="{ option, toggle }">
                <div
                  class="sl-aggregation-select__option"
                  @click="option.isSpecial && toggle()"
                >
                  <SlCheckbox
                    v-if="!option.noCheckbox"
                    :value="option.status"
                  />
                  <div
                    :class="['sl-aggregation-select__option-label', {
                      'sl-aggregation-select__option-label--special': option.isSpecial
                    }]"
                  >
                    {{ option.label }}
                  </div>
                </div>
              </template>
            </SlSelect>
          </div>
        </div>
      </template>
      <template v-if="config.timeView">
        <div class="create-widget__header">
          {{ config.timeView.name }}
        </div>
        <div class="create-widget__block">
          <div class="create-widget__control">
            <SlSelect
              v-model="config.timeView.val"
              :options="config.timeView.selection"
              @input="(value) => updateWidget({
                block: blockTypes.TIME_VIEW,
                requestKey: config.timeView.requestKey,
                value
              })"
            />
          </div>
        </div>
      </template>
      <template v-if="config.sorting">
        <div class="create-widget__header">
          {{ config.sorting.name }}
        </div>
        <div class="create-widget__block">
          <div class="create-widget__control">
            <SlSelect
              v-model="config.sorting.val"
              :options="config.sorting.selection"
              @input="(value) => updateWidget({
                block: blockTypes.SORTING,
                value
              })"
            />
          </div>
        </div>
      </template>
      <template>
        <div class="create-widget__header">
          {{ $t('Web.Dashboard.Widget.Filter') }}
        </div>
        <div class="create-widget__block create-widget__block--filter">
          <SlButton
            v-if="!isFilterExist"
            variant="secondary"
            color="grey"
            @click="handleAddFilter"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-16 color-grey-80"
              />
            </template>
            {{ $t('Web.Dashboard.Widget.AddFilter') }}
          </SlButton>
          <SlButton
            v-else
            variant="secondary"
            color="grey"
            @click="handleEditFilter"
          >
            <template #prepend>
              <icon
                data="@icons/pen.svg"
                class="fill-off size-16 color-grey-80"
              />
            </template>
            {{ $t('Web.Dashboard.Widget.EditFilter') }}
          </SlButton>
          <SlButton
            v-if="isFilterExist"
            variant="secondary"
            color="grey"
            @click="handleDeleteFilter"
          >
            <template #prepend>
              <icon
                data="@icons/trash.svg"
                class="fill-off size-16 color-grey-80"
              />
            </template>
            {{ $t('Web.Dashboard.Widget.DeleteFilter') }}
          </SlButton>
        </div>
      </template>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="isUpdating"
          @click="handleSubmit"
        >
          {{ submitButtonLabel }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { blockTypes, controlType, requestKeys } from '@/config/dashboard/analytics.config';
import { getTooltip } from '@/helpers/shared/tooltip';
import { scrollToInvalidField } from '@/helpers/shared/validation';

export default {
  name: 'CreateWidgetModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.CREATE_DASHBOARD_WIDGET,
      blockTypes,
      controlType,
      dashboardId: null,
      widgetId: null,
      configId: null,
      config: null,
      widgetName: '',
      // is used to prevent the form from being submitted before server-side changes are applied
      isUpdating: false,
      // is used to prevent unnecessary discardWidgetConfiguration calls
      isSaved: false,
      isLoading: false
    };
  },
  computed: {
    submitButtonLabel() {
      return this.widgetId
        ? this.$t('Web.Modals.BtnSave')
        : this.$t('Web.Modals.BtnCreate');
    },
    isFilterExist() {
      return typeof this.config.filter === 'number';
    }
  },
  methods: {
    ...mapActions({
      createWidgetCache: 'dashboard/analytics/createWidgetCache',
      fetchWidgetConfiguration: 'dashboard/analytics/fetchWidgetConfiguration',
      updateWidgetConfiguration: 'dashboard/analytics/updateWidgetConfiguration',
      saveWidgetConfiguration: 'dashboard/analytics/saveWidgetConfiguration',
      discardWidgetConfiguration: 'dashboard/analytics/discardWidgetConfiguration',
      addWidget: 'dashboard/analytics/addWidget'
    }),
    getTooltip,
    async onModalShow() {
      try {
        this.isLoading = true;

        this.configId = await this.createWidgetCache(this.widgetId);
        this.config = await this.fetchWidgetConfiguration(this.configId);
        this.widgetName = this.config?.name || '';
      } finally {
        this.isLoading = false;
      }
    },
    onModalHide() {
      if (!this.isSaved) {
        this.discardWidgetConfiguration(this.getRequestParams());
      }

      this.dashboardId = null;
      this.widgetId = null;
      this.configId = null;
      this.config = null;
      this.widgetName = '';
      this.isUpdating = false;
      this.isSaved = false;
      this.isLoading = false;
    },
    isComparedBlock(index) {
      return !index && this.config.reportConfig.length > 1;
    },
    isSplitControls({ requestKey }) {
      return requestKey === requestKeys.FORECAST_VERSION || requestKey === requestKeys.ARCHIVE_PERIOD;
    },
    isLabelSpecial(aggregation) {
      if (!aggregation) {
        return false;
      }

      const { val, selection } = aggregation;
      const options = this.getAggregationOptions(selection);

      return options.some(({ value, isSpecial }) => val.includes(value) && isSpecial);
    },
    getAggregationOptions(options) {
      if (!options) {
        return [];
      }

      return options.map((item, index) => ({
        ...item,
        isSpecial: index <= 1
      }));
    },
    getAggregationLabel({ val, selection } = {}) {
      if (!val) {
        return '';
      }

      return selection.reduce((acc, { status, label }) => {
        if (status) {
          acc.push(label);
        }

        return acc;
      }, []).join(', ');
    },
    getChartControlOptions(selection) {
      return selection.map(({ value, label }) => ({
        value,
        icon: require(`@icons/${value}.svg`),
        tooltip: getTooltip(label)
      }));
    },
    getRequestParams() {
      return {
        dashboardId: this.dashboardId,
        widgetId: this.configId
      };
    },
    async updateWidget(body) {
      try {
        this.isUpdating = true;

        await this.updateWidgetConfiguration({
          params: this.getRequestParams(),
          body
        });

        const config = await this.fetchWidgetConfiguration(this.configId);

        if (config) {
          this.config = config;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async updateHistoricalPeriod({ validate, value, ...body }) {
      const { valid } = await validate(value);

      if (!valid) {
        return;
      }

      this.updateWidget({
        ...body,
        value: +value
      });
    },
    updateFilter(value) {
      this.updateWidget({
        block: blockTypes.FILTER,
        value
      });
    },
    updateAggregation(selected) {
      this.updateWidget({
        block: blockTypes.AGGREGATION,
        requestKey: this.config.aggregation.requestKey,
        value: selected
      });
    },
    handleAddFilter() {
      this.showModal(modalsId.ANALYTICS_FILTER, {
        saveCallback: this.updateFilter
      });
    },
    async handleEditFilter() {
      this.showModal(modalsId.ANALYTICS_FILTER, {
        filterId: this.config.filter
      });
    },
    handleDeleteFilter() {
      this.updateWidget({
        block: blockTypes.FILTER,
        value: null
      });
    },
    async handleSubmit() {
      try {
        if (this.isUpdating) {
          return;
        }

        const isValid = await this.$refs.observer.validate();

        if (!isValid) {
          return scrollToInvalidField(this.$refs.observer);
        }

        this.isLoading = true;

        await this.updateWidget({
          block: blockTypes.NAME,
          value: this.widgetName
        });

        const widgetId = await this.saveWidgetConfiguration(this.getRequestParams());

        if (!this.widgetId) {
          this.addWidget(widgetId);
        }

        this.isSaved = true;
        this.hideModal(this.id);
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/modals/dashboard/create-widget";
</style>
